<script lang="tsx">
import { defineComponentCoreIcon } from '@core/app/components/core/icon/CoreIcon.vue'

export default defineComponentCoreIcon(
    <svg xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 32 33"
        fill="none"
        width="32"
        height="32"
    >
        <path fill="currentColor"
            fill-rule="evenodd"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-width=".5"
            d="M13.352 22.229a.64.64 0 0 1 0-.857l4.477-4.824-4.477-4.824a.64.64 0 0 1 0-.857.534.534 0 0 1 .796 0l4.61 4.967a1.066 1.066 0 0 1 0 1.428l-4.61 4.967a.534.534 0 0 1-.796 0Z"
            clip-rule="evenodd"
        />
        <circle cx="16"
            cy="16.5"
            r="15"
            stroke="currentStroke"
            stroke-width="2"
        />
    </svg>
)

</script>

<style lang="scss" scoped>

</style>
